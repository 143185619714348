<template>
  <div class="notification">
    <div class="modal__body notification__body">
      <div class="notification__text">
        {{ title }}
      </div>
      <div class="uploading">
        <div class="uploading__loader" v-if="isUploading">
          <loader info container small />
        </div>
        <div class="uploading__text">{{ text }}</div>
      </div>
    </div>
    <div class="modal__footer">
      <button class="button button_secondary button_md" @click="clickHandler">
        close
      </button>
    </div>
  </div>
</template>

<script>
import { MSG_STREAM_LOST } from "@/constants";
import { reloadPage } from "@/utils/system";
import { saveLog } from "@/modules/logger";
import { mapState } from "vuex";
import Loader from "@/components/loader";

export default {
  components: { Loader },

  props: {
    options: { type: Object, required: true },
  },

  computed: {
    ...mapState(["uploading"]),

    title() {
      return MSG_STREAM_LOST;
    },

    text() {
      return this.isUploading
        ? "Uploading your recordings, please wait."
        : "All recordings are uploaded!";
    },

    isUploading() {
      return Object.values(this.uploading).some(({ loading }) => loading);
    },
  },

  methods: {
    async clickHandler() {
      if (this.isUploading) return;
      saveLog("reload-page");

      return reloadPage();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/basic/variables";

.uploading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  &__loader {
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  &__text {
    color: $color-info-dark;
  }
}
</style>
